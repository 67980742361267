/** @format */

import React from "react";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { Box, Container } from "@mui/material";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    minHeight: "100vh",
    paddingTop: theme.spacing(25),
    paddingBottom: theme.spacing(10),
  },
}));

const TermsOfService: React.FC = () => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Container maxWidth="md">
        <h1 className="text-3xl font-bold mb-6">Terms of Service</h1>
        <p className="mb-4">Last Updated: October 9, 2023</p>
        <p className="mb-4">Welcome to eon.health!</p>
        <p className="mb-4">
          These Terms of Service ("Terms") govern your use of the eon.health
          application (the "App"), provided by eon.health ("we," "us," or
          "our"). By accessing or using the App, you agree to comply with and be
          bound by these Terms. If you do not agree to these Terms, please do
          not use the App.
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-4">
          1. Acceptance of Terms
        </h2>
        <p className="mb-4">
          By using the App, you affirm that you are at least 18 years old or
          have the legal capacity to enter into these Terms. If you are using
          the App on behalf of an organization, you represent and warrant that
          you have the authority to bind that organization to these Terms.
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-4">2. Privacy</h2>
        <p className="mb-4">
          Your use of the App is also governed by our Privacy Policy, which can
          be found at link to Privacy Policy. Please review our Privacy Policy
          to understand how we collect, use, and disclose your information.
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-4">3. User Data</h2>
        <p className="mb-4">
          To provide health optimization and longevity recommendations, the App
          may collect various data from you, including but not limited to your
          lifestyle choices, health data, and biological age information. By
          using the App, you grant us the right to collect, process, and analyze
          this data to provide actionable feedback.
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-4">4. App Usage</h2>
        <ul className="list-disc pl-6 mb-4">
          <li>
            You agree to use the App solely for its intended purpose of
            improving health and reducing biological age.
          </li>
          <li>
            You agree not to use the App for any unlawful or unauthorized
            purpose or in a manner that violates any applicable laws or
            regulations.
          </li>
          <li>
            You are responsible for maintaining the security of your account
            credentials and any activity that occurs under your account.
          </li>
          <li>
            We reserve the right to suspend or terminate your access to the App
            at our discretion, without notice, if you violate these Terms.
          </li>
        </ul>

        <h2 className="text-2xl font-semibold mt-6 mb-4">
          5. Intellectual Property
        </h2>
        <ul className="list-disc pl-6 mb-4">
          <li>
            The App and its content, including but not limited to text,
            graphics, logos, and software, are the property of eon.health and
            protected by intellectual property laws. You may not use, reproduce,
            or distribute any content from the App without our prior written
            permission.
          </li>
          <li>
            You grant us a non-exclusive, worldwide, royalty-free license to use
            any content you submit to the App, for the purpose of providing the
            services described herein.
          </li>
        </ul>

        <h2 className="text-2xl font-semibold mt-6 mb-4">6. Disclaimers</h2>
        <ul className="list-disc pl-6 mb-4">
          <li>
            The App is intended for informational and educational purposes only
            and should not be considered a substitute for professional medical
            advice.
          </li>
          <li>
            We do not make any warranties or guarantees regarding the accuracy,
            completeness, or reliability of the information provided by the App.
          </li>
        </ul>

        <h2 className="text-2xl font-semibold mt-6 mb-4">
          7. Limitation of Liability
        </h2>
        <p className="mb-4">
          We shall not be liable for any direct, indirect, incidental, special,
          consequential, or punitive damages arising out of or in connection
          with the use of the App.
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-4">
          8. Changes to Terms
        </h2>
        <p className="mb-4">
          We may modify these Terms at any time by posting the revised Terms on
          the App. Your continued use of the App after any such changes will
          constitute your acceptance of the updated Terms.
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-4">9. Termination</h2>
        <p className="mb-4">
          We may terminate or suspend your access to the App at any time,
          without notice, for any reason.
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-4">10. Contact Us</h2>
        <p className="mb-4">
          If you have any questions or concerns about these Terms or the App,
          please contact us at tremblerz@gmail.com.
        </p>
      </Container>
    </Box>
  );
};

export default TermsOfService;
