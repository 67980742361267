/** @format */

import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  TextField,
  Button,
  InputAdornment,
  Snackbar,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { getGradientShade } from "./utils";

const StyledContainer = styled(Container)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: theme.spacing(20, 0),
  backgroundColor: "#ffffff",
}));

const StyledForm = styled("form")(({ theme }) => ({
  display: "flex",
  width: "100%",
  maxWidth: 800,
  marginTop: theme.spacing(10),
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  flex: 1,
  "& .MuiOutlinedInput-root": {
    borderTopLeftRadius: theme.shape.borderRadius,
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderRadius: 25,
    height: "56px",
    transition: theme.transitions.create([
      "border-color",
      "box-shadow",
      "background-color",
    ]),
    "&:hover, &.Mui-focused": {
      backgroundColor: "transparent",
      "&::before": {
        content: '""',
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        borderRadius: "inherit",
        padding: "1px", // Adjust this value to change border thickness
        background: getGradientShade(1),
        mask: "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
        maskComposite: "exclude",
        WebkitMaskComposite: "xor",
        pointerEvents: "none",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.23)", // Light gray color
    },
    "&:hover .MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline":
      {
        borderColor: "transparent",
      },
  },
}));

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "isValid",
})<{ isValid: boolean }>(({ theme, isValid }) => ({
  height: "56px",
  borderRadius: 25,
  background: isValid
    ? "linear-gradient(to right, #eb2caa, #8c4bd4)"
    : "#e0e0e0",
  color: isValid ? "#ffffff" : "#757575",
  marginLeft: theme.spacing(1), // Add spacing between input and button
  "&:hover": {
    background: isValid ? getGradientShade(2) : "#d5d5d5",
  },
}));

const ContactForm: React.FC = () => {
  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const validateEmail = (email: string) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  useEffect(() => {
    setIsValidEmail(validateEmail(email));
  }, [email]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isValidEmail) {
      setIsSubmitting(true);
      try {
        const url = "https://api.eon.health/subscribe_email";
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        });

        const data = await response.json();
        if (response.ok) {
          setSnackbarMessage("Thank you for subscribing!");
          setEmail("");
        } else {
          console.error("Error:", data, response);
          setSnackbarMessage(
            data.message || "An error occurred. Please try again."
          );
        }
      } catch (error) {
        console.error("Error:", error);
        setSnackbarMessage("An error occurred. Please try again.");
      } finally {
        setIsSubmitting(false);
        setSnackbarOpen(true);
      }
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <StyledContainer maxWidth={false}>
      <Typography
        variant="h4"
        align="center"
        gutterBottom
        sx={{ fontWeight: "light" }}
      >
        Thrive as Your Best Self
      </Typography>
      <Typography
        variant="subtitle1"
        align="center"
        color="textSecondary"
        gutterBottom
      >
        Learn More About EON.HEALTH
      </Typography>
      <StyledForm onSubmit={handleSubmit}>
        <StyledTextField
          fullWidth
          placeholder="Your Email Address"
          name="email"
          type="email"
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {/* You can add an email icon here if desired */}
              </InputAdornment>
            ),
          }}
        />
        <StyledButton
          type="submit"
          variant="contained"
          disableElevation
          isValid={isValidEmail}
        >
          {isSubmitting ? "Sending..." : "Sign Up"}
        </StyledButton>
      </StyledForm>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
      />
    </StyledContainer>
  );
};

export default ContactForm;
