/** @format */

import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import { getGradientShade } from "./utils";

// Import your custom icons here
import HolisticAnalysisIcon from "../assets/holistic.svg";
import CopilotIcon from "../assets/copilot.svg";
import CollectiveLearningIcon from "../assets/collective.svg";

const Services: React.FC = () => {
  const services = [
    {
      icon: HolisticAnalysisIcon,
      title: "Holistic Analysis from Your Pattern",
      description:
        "Examines your patterns to uncover hidden insights that impact your longevity.",
      color: "#FFF0F0",
    },
    {
      icon: CopilotIcon,
      title: "Copilot for Personalized Feedback",
      description:
        "Adapts to your progress, and support to keep you on track towards your goals.",
      color: "#FFF0FF",
    },
    {
      icon: CollectiveLearningIcon,
      title: "Collective Learning with Hive",
      description:
        "leverages collective intelligence, allowing you to learn from others experiments and experiences",
      color: "#F0F0FF",
    },
  ];

  return (
    <Box
      sx={{
        py: 8,
        px: 2,
        textAlign: "center",
        maxWidth: "1200px",
        margin: "0 auto",
        marginBottom: 10,
      }}
    >
      <Typography
        variant="h4"
        component="h2"
        sx={{ fontWeight: 300, mb: 1, paddingTop: 10, pb: 2 }}
      >
        We Provide
      </Typography>
      <Box
        sx={{
          width: "120px",
          height: "2px",
          backgroundColor: "#000",
          margin: "0 auto",
          mb: 4,
        }}
      />
      <Typography
        variant="body1"
        sx={{ mb: 15, maxWidth: 800, mx: "auto", mt: 10 }}
      >
        EON.HEALTH empowers you to understand yourself better than ever through
        a powerful combination of your personal data, AI-driven insights, and
        collective wisdom from peers, enabling you to take control of your
        health and enhance your longevity journey.
      </Typography>

      <Grid container spacing={4} justifyContent="center">
        {services.map((service, index) => (
          <Grid item xs={12} sm={4} key={index}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Box sx={{ mb: 2, "& svg": { width: 48, height: 48 } }}>
                <img
                  src={service.icon}
                  alt={service.title}
                  width={100}
                  height={100}
                />
              </Box>
              <Box
                sx={{
                  width: "120px",
                  height: "2px",
                  //   backgroundColor: "#000",
                  background: getGradientShade(index),
                  margin: "0 auto",
                  mb: 4,
                }}
              />
              <Typography
                variant="h6"
                component="h3"
                sx={{ mb: 2, fontWeight: 600 }}
              >
                {service.title}
              </Typography>
              <Typography variant="body2" sx={{ maxWidth: "250px" }}>
                {service.description}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Services;
