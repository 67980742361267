/** @format */

import React, { useState } from "react";
import {
  Container,
  Typography,
  Box,
  TextField,
  Button,
  Grid,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { getGradientShade } from "./utils";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: "#fff",
    color: "#000",
    paddingTop: theme.spacing(30),
    paddingBottom: theme.spacing(8),
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: theme.spacing(10),
  },
  title: {
    fontWeight: "100 !important",
    marginBottom: theme.spacing(2),
    textAlign: "center",
    fontFamily: "'Raleway', sans-serif !important",
  },
  subtitle: {
    fontWeight: 300,
    paddingTop: theme.spacing(6),
    marginBottom: theme.spacing(4),
    textAlign: "center",
    maxWidth: "400px",
    fontFamily: "'Raleway', sans-serif !important",
  },
  form: {
    marginTop: theme.spacing(10),
    width: "100%",
    maxWidth: "600px",
  },
  input: {
    marginBottom: theme.spacing(2),
  },
  submitButton: {
    width: "24%",
    borderRadius: "55px !important",
    backgroundColor: "rgba(216, 216, 216, 1) !important",
    // disable shadow
    boxShadow: "none !important",
    color: "#fff",
    "&:hover": {
      background:
        "linear-gradient(to right, #FF9250, #FF6473, #FE26A0, #CF34B9, #7852DC, #017AFF) !important",
    },
  },
}));

const ContactPage: React.FC = () => {
  const classes = useStyles();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log("Form submitted:", formData);
  };

  return (
    <Box className={classes.root}>
      <Container maxWidth="lg">
        <Box className={classes.content}>
          <Typography variant="h2" className={classes.title}>
            Contact Us
          </Typography>
          {/* Draw a horizontal line */}
          <Box
            width="160px"
            height="1px"
            bgcolor="#000"
            margin="auto"
            marginBottom="16px"
            marginTop={2}
          />
          <Typography variant="body1" className={classes.subtitle}>
            By analyzing information of you which is collected from six
            different pillars, Our algorithm assesses and suggests the best way
            to enhance your longevity.
          </Typography>

          <form onSubmit={handleSubmit} className={classes.form}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  className={classes.input}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  className={classes.input}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Message"
                  name="message"
                  multiline
                  rows={4}
                  value={formData.message}
                  onChange={handleInputChange}
                  className={classes.input}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent="flex-end">
                  <Button
                    type="submit"
                    variant="contained"
                    className={classes.submitButton}
                  >
                    Send Email
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Container>
    </Box>
  );
};

export default ContactPage;
