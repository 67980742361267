/** @format */

import React, { useState } from "react";
import { Container, Typography, Box, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { Link } from "react-router-dom";
import EmailIcon from "@mui/icons-material/Email";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    paddingTop: theme.spacing(35),
  },
  content: {
    textAlign: "left",
  },
  message: {
    fontWeight: "100 !important",
    marginBottom: theme.spacing(4),
    fontSize: "2rem",
    fontFamily: "'Ralway', sans-serif !important",
  },
  divider: {
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    height: "1px",
    width: "400px",
    marginTop: "400px",
  },
  emailButton: {
    marginLeft: "0px !important",
    paddingLeft: "0px !important",
    marginTop: "40px !important",
    color: "rgba(255, 255, 255, 0.5) !important",
    // borderColor: "#fff !important",
    "&:hover": {
      // backgroundColor: "rgba(255, 255, 255, 0.1) !important",
      color: "#fff !important",
    },
  },
}));

const PilotTest: React.FC = () => {
  const classes = useStyles();
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Box className={classes.root}>
      <Container maxWidth="xl">
        <Box className={classes.content}>
          <Typography variant="h4" className={classes.message}>
            We are currently done with our last pilot test.
            <br />
            Please contact us through email.
          </Typography>
          <Button
            component={Link}
            to="/contact"
            variant="outlined"
            className={classes.emailButton}
            startIcon={<EmailIcon />}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            {isHovered ? "Send Email" : "Send Email"}
          </Button>
          <Box className={classes.divider} />
        </Box>
      </Container>
    </Box>
  );
};

export default PilotTest;
