/** @format */

export const getGradientShade = (index: number) => {
  const id = index % 3;
  switch (id) {
    case 0:
      return "linear-gradient(to right, #ff7c61, #fa27a2)";
    case 1:
      return "linear-gradient(to right, #eb2caa, #8c4bd4)";
    case 2:
      return "linear-gradient(to right, #ad40c7, #017aff)";
    default:
      return "red";
  }
};
