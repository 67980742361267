/** @format */

import React from "react";
import Hero from "./Hero";
import Services from "./Services";
import ProductShowcase from "./ProductShowcase";
import LongevityPillars from "./LongevityPillars";
import ContactForm from "./ContactForm";

const Home: React.FC = () => {
  return (
    <>
      <Hero />
      <Services />
      <ProductShowcase />
      <LongevityPillars />
      <ContactForm />
    </>
  );
};

export default Home;
