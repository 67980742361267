/** @format */

import React from "react";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { Box, Container } from "@mui/material";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    minHeight: "100vh",
    paddingTop: theme.spacing(25),
    paddingBottom: theme.spacing(10),
  },
}));

const PrivacyPolicy: React.FC = () => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Container maxWidth="md">
        <h1 className="text-3xl font-bold mb-6">
          Privacy Policy for EON.health
        </h1>
        <p className="mb-4">Effective Date: May, 2023</p>
        <p className="mb-4">
          This Privacy Policy describes how EON.health ("we," "us," or "our")
          collects, uses, shares, and protects personal information obtained
          through our smartphone app. By using the App, you agree to the terms
          of this Privacy Policy.
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-4">
          1. Information We Collect
        </h2>
        <ul className="list-disc pl-6 mb-4">
          <li>
            <strong>Personal Information:</strong> We do not collect any
            personally identifiable information from users of the App, such as
            names, and phone numbers with the exception of email addresses.
          </li>
          <li>
            <strong>Health Information:</strong> The health information
            collected is primarily utilized to provide personalized services to
            users. This may include generating customized recommendations,
            delivering relevant content, and offering tailored insights related
            to health and well-being.
          </li>
          <li>
            <strong>Encrypted Communication:</strong> The App enables encrypted
            communication between the backend server and the smartphone app.
          </li>
        </ul>

        <h2 className="text-2xl font-semibold mt-6 mb-4">
          2. Use of Information
        </h2>
        <ul className="list-disc pl-6 mb-4">
          <li>
            <strong>Functionality:</strong> We use the data collected during
            encrypted communication solely for the purpose of providing the
            intended functionality of the App. This includes ensuring secure
            communication and maintaining the performance and availability of
            the App.
          </li>
          <li>
            <strong>Service Improvement:</strong> We may use aggregated and
            anonymized data for analytical purposes to improve our services,
            enhance user experience, and identify trends and patterns. This data
            will not be used to identify individual users.
          </li>
        </ul>

        <h2 className="text-2xl font-semibold mt-6 mb-4">
          3. Information Sharing
        </h2>
        <ul className="list-disc pl-6 mb-4">
          <li>
            <strong>Third-Party Service Providers:</strong> We may engage
            third-party service providers to assist with hosting the App on our
            chosen platform. These service providers will have access to the
            encrypted data solely for the purpose of hosting and providing the
            services necessary for the operation of the App. They are bound by
            strict confidentiality obligations and are not permitted to use the
            data for any other purpose. We do not share the data with any
            third-party service provider for any kind of tracking.
          </li>
          <li>
            <strong>Legal Requirements:</strong> We may disclose personal
            information if required to do so by law or if we believe in good
            faith that such disclosure is necessary to comply with legal
            obligations, protect our rights or safety, or investigate fraud.
          </li>
        </ul>

        <h2 className="text-2xl font-semibold mt-6 mb-4">4. Data Security</h2>
        <ul className="list-disc pl-6 mb-4">
          <li>
            <strong>Encryption:</strong> We take reasonable measures to protect
            the data collected during encrypted communication. This includes
            using industry-standard encryption protocols and ensuring secure
            transmission and storage.
          </li>
          <li>
            <strong>Access Control:</strong> Access to the encrypted data is
            limited to the owners of the App and authorized personnel who
            require access for maintenance and operational purposes.
          </li>
        </ul>

        <h2 className="text-2xl font-semibold mt-6 mb-4">
          5. Children's Privacy
        </h2>
        <p className="mb-4">
          The App is not intended for use by individuals under the age of 13. We
          do not knowingly collect personal information from children under 13
          years of age. If we become aware that we have collected personal
          information from a child under the age of 13, we will take steps to
          delete the information as soon as possible.
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-4">
          6. Changes to the Privacy Policy
        </h2>
        <p className="mb-4">
          We reserve the right to update or modify this Privacy Policy at any
          time. Any changes will be effective immediately upon posting the
          revised Privacy Policy on the App. We encourage you to review this
          Privacy Policy periodically for any updates.
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-4">7. Contact Us</h2>
        <p className="mb-4">
          If you have any questions, concerns, or requests regarding this
          Privacy Policy or our privacy practices, please contact us at
          tremblerz@gmail.com.
        </p>
      </Container>
    </Box>
  );
};

export default PrivacyPolicy;
