/** @format */

// components/Hero.tsx
import React from "react";
import { Box, Typography, Container } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import pillar from "../assets/pillar.png";
import sandbox from "../assets/sandbox.png";
import masschallenge from "../assets/masschallenge.png";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: "100vh",
    backgroundColor: theme.palette.primary.main,
    color: "#ffffff",
    display: "flex",
    flexDirection: "column",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: "100vh",
    paddingTop: theme.spacing(30), // Adjust based on header height
    paddingBottom: theme.spacing(30),
  },
  content: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  title: {
    fontSize: "6rem !important",
    fontWeight: 300,
    lineHeight: 1.2,
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    fontSize: "2.5rem",
    maxWidth: "800px",
    marginBottom: theme.spacing(8), // Increased spacing before partner logos
    fontWeight: 300,
    paddingTop: theme.spacing(10),
  },
  boldText: {
    fontWeight: "bold",
    // add a hover effect that changes the color
    // "&:hover": {
    background:
      "linear-gradient(to right, #FF9250, #FF6473, #FE26A0, #CF34B9, #7852DC, #017AFF)",
    "-webkit-background-clip": "text",
    backgroundClip: "text",
    color: "transparent", // Fallback for browsers that do not support text-fill-color
    "-webkit-text-fill-color": "transparent", // Specific to WebKit browsers
    // },
  },
  partners: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    "& img": {
      height: "30px",
      marginRight: theme.spacing(4),
    },
    marginTop: theme.spacing(30), // Increased spacing before partner logos
  },
}));

const Hero: React.FC = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Container maxWidth="xl" className={classes.container}>
        <Box className={classes.content}>
          <Typography variant="h1" className={classes.title}>
            Your Body Talks
            <br />
            We Translate
          </Typography>
          <Typography variant="h5" className={classes.subtitle}>
            Introducing <span className={classes.boldText}>EON.HEALTH</span>,
            your AI Companion for a Unique journey to Holistic Longevity and
            Maximum Functionality.
          </Typography>
        </Box>
        <Box className={classes.partners}>
          <img src={sandbox} alt="MIT Sandbox" />
          <img src={pillar} alt="MIT-Pillar AI Collective" />
          <img src={masschallenge} alt="MassChallenge" />
        </Box>
      </Container>
    </Box>
  );
};

export default Hero;
