/** @format */

// components/ProductShowcase.tsx
import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import showCaseImg from "../assets/showcase.png";

const useStyles = makeStyles((theme: Theme) => ({
  section: {
    padding: theme.spacing(8, 0),
    backgroundColor: theme.palette.background.paper,
    backgroundImage: `url(${showCaseImg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "100vh", // Adjust the height as needed
    width: "100%",
  },
  image: {
    width: "100%",
    height: "auto",
  },
}));

const ProductShowcase: React.FC = () => {
  const classes = useStyles();

  return (
    <Box className={classes.section}>
      <Container maxWidth="lg">
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6}>
            {/* Content here if needed */}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default ProductShowcase;
