/** @format */

import React from "react";
import { Container, Typography, Box, Grid, Avatar } from "@mui/material";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

import kyun from "../assets/people/kyun.png";
import abhi from "../assets/people/abhi.png";
import johnny from "../assets/people/johnny.jpg";
import luke from "../assets/people/luke.png";
import priyamvad from "../assets/people/priyam.jpg";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    minHeight: "100vh",
    paddingTop: theme.spacing(25),
    paddingBottom: theme.spacing(10),
  },
  title: {
    fontWeight: 200,
    marginBottom: theme.spacing(2),
    fontSize: "4rem",
    paddingBottom: theme.spacing(8),
  },
  subtitle: {
    fontWeight: 300,
    paddingBottom: theme.spacing(4),
    fontSize: "2rem !important",
  },
  description: {
    fontWeight: 300,
    paddingBottom: theme.spacing(6),
    maxWidth: "600px",
  },
  divider: {
    backgroundColor: "silver",
    height: "2px",
    width: "200px",
    margin: `${theme.spacing(8)}px 0`,
  },
  teamTitle: {
    fontWeight: 300,
    paddingTop: theme.spacing(12),
    paddingBottom: theme.spacing(6),
    fontSize: "2.5rem",
  },
  teamMember: {
    marginBottom: theme.spacing(6),
  },
  avatar: {
    width: "500px",
    marginBottom: theme.spacing(2),
  },
  memberName: {
    fontWeight: 500,
    marginBottom: theme.spacing(1),
  },
  memberTitle: {
    fontWeight: 300,
    marginBottom: theme.spacing(1),
    color: "#aaa",
  },
  memberBio: {
    fontWeight: 300,
    marginBottom: theme.spacing(2),
  },
  socialLinks: {
    "& > *": {
      marginRight: theme.spacing(1),
    },
  },
}));

const AboutUs: React.FC = () => {
  const classes = useStyles();

  const teamMembers = [
    {
      name: "Ki-Youn Jung",
      title: "MSc, MIT Sloan MBA",
      role: "Co-founder & CEO",
      bio: "Ki-Youn was a corporate intrapreneur, developing an AI+IoT platform strategy for $20B global business. She was an architect for SoC(System on Chip) and designed semiconductor processors.",
      avatar: kyun,
      twitter: "just_kyunjung",
      linkedin: "kiyoun-jung",
    },
    {
      name: "Abhishek Singh",
      title: "Ph.D Candidate MIT Media Lab",
      role: "Co-founder & CTO",
      bio: "Abhishek is a scientist, engineer and hacker with extensive experience in developing novel ideas and building large-scale systems. His research focuses on Decentralized AI for precision medicine.",
      avatar: abhi,
      twitter: "tremblerz",
      linkedin: "tremblerz",
    },
    {
      name: "Seonghyun Nam",
      title: "Harvard GSD MDes",
      role: "Founding Member & Design Lead",
      bio: "Seonghyun is a UX/UI designer pursuing his Master in Design Studies(MDes) in the Harvard Graduate School of Design. He has an extensive experience with design and development of digital products.",
      avatar: johnny,
      twitter: undefined,
      linkedin: "seong-hyun-nam-763029193",
    },
    {
      name: "Luke Hanuska",
      title: "Undergraduate Student at Virginia Tech",
      role: "Founding Member & Software Engineer",
      bio: "Luke is a software engineer with experience in full-stack development. He is currently pursuing a degree in Computer Science at Virginia Tech.",
      avatar: luke,
      twitter: undefined,
      linkedin: "luke-hanuska",
    },
    {
      name: "Priyamvad Singh",
      title: "Undergraduate Student at VIT Vellore",
      role: "Founding Member & Software Engineer",
      bio: "Priyamvad is a software engineer with experience in full-stack development. He is currently pursuing a degree in Computer Science at VIT Vellore.",
      avatar: priyamvad,
      twitter: undefined,
      linkedin: "priyamvad-singh-916692237",
    },
  ];

  return (
    <Box className={classes.root}>
      <Container maxWidth="xl">
        <Typography variant="h1" className={classes.title}>
          About Us
        </Typography>
        <Typography variant="h2" className={classes.subtitle}>
          "Thrive as your best self"
        </Typography>
        <Typography variant="body1" className={classes.description}>
          We are committed to revolutionizing the field of medicine through the
          development of cutting-edge technology that individuals to take
          control of their health and enhance their longevity.
        </Typography>

        <Box className={classes.divider} />

        <Typography variant="h3" className={classes.teamTitle}>
          Meet the Team
        </Typography>

        <Grid container spacing={4}>
          {teamMembers.map((member, index) => (
            <Grid item xs={12} key={index} className={classes.teamMember}>
              <Grid container alignItems="center">
                {/* Avatar Grid */}
                <Grid item xs={12} sm={2}>
                  <Avatar
                    src={member.avatar}
                    className={classes.avatar}
                    sx={{ width: 130, height: 130 }}
                  />
                </Grid>
                {/* Text Content Grid */}
                <Grid item xs={12} sm={10}>
                  <Typography variant="h5" className={classes.memberName}>
                    {member.name}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    className={classes.memberTitle}
                  >
                    {member.title}
                  </Typography>
                  <Typography variant="h6" className={classes.memberTitle}>
                    {member.role}
                  </Typography>
                  <Typography variant="body2" className={classes.memberBio}>
                    {member.bio}
                  </Typography>
                  <Box className={classes.socialLinks}>
                    {member.twitter && (
                      <a
                        href={`https://twitter.com/${member.twitter}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <TwitterIcon color="secondary" />
                      </a>
                    )}
                    {member.linkedin && (
                      <a
                        href={`https://linkedin.com/in/${
                          member.linkedin ?? ""
                        }`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <LinkedInIcon color="secondary" />
                      </a>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default AboutUs;
