/** @format */

import React, { useEffect } from "react";
import { Box, Container, Typography, Link, styled } from "@mui/material";
import { getGradientShade } from "./utils";

const apiKey = "AIzaSyCIIp66h0VW8XhTTmfIt-H65fUeTZrM65o";
const StyledFooter = styled(Box)(({ theme }) => ({
  backgroundColor: "#1c1c1c",
  color: "#ffffff",
}));

const MapContainer = styled(Box)({
  width: "100%",
  height: "400px",
  position: "relative",
  overflow: "hidden",
});

const StaticMap = styled("img")({
  width: "100%",
  height: "100%",
  objectFit: "cover",
  filter: "grayscale(100%)",
});

const CustomMarker = styled(Box)<{ ishovered: string }>(({ ishovered }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  width: "30px",
  height: "45px",
  backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="30" height="45" viewBox="0 0 30 45"><path d="M15 0C6.716 0 0 6.716 0 15c0 8.284 15 30 15 30s15-21.716 15-30c0-8.284-6.716-15-15-15z" fill="${
    ishovered === "true" ? "url(%23gradient)" : "%23808080"
  }"/><circle cx="15" cy="15" r="6" fill="white"/><defs><linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%"><stop offset="0%" stop-color="%23FF9250"/><stop offset="20%" stop-color="%23FF6473"/><stop offset="40%" stop-color="%23FE26A0"/><stop offset="60%" stop-color="%23CF34B9"/><stop offset="80%" stop-color="%237852DC"/><stop offset="100%" stop-color="%23017AFF"/></linearGradient></defs></svg>')`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
  transform: "translate(-50%, -100%)",
  cursor: "pointer",
  transition: "transform 0.3s ease",
  "&:hover": {
    transform: "translate(-50%, -110%) scale(1.1)",
  },
}));

const ContentContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(10),
  paddingBottom: theme.spacing(10),
}));

const NavLinks = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  flexWrap: "wrap",
  gap: theme.spacing(3),
  marginBottom: theme.spacing(20),
}));

const StyledLink = styled(Link)(({ theme }) => ({
  color: "#ffffff",
  textDecoration: "none",
  "&:hover": {
    textDecoration: "underline",
  },
}));

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();
  const [ishovered, setIshovered] = React.useState(false);
  const address = "MIT Sloan School of Management, Cambridge, MA 02142";
  const mapStyles = [
    // "style=feature:all|element:labels|visibility:off",
    // "style=feature:administrative|element:geometry|visibility:off",
    "style=feature:landscape|element:geometry|color:0xf0f0f0",
    "style=feature:water|element:geometry|color:0xc9c9c9",
    // "style=feature:road|element:geometry|color:0xffffff",
    "style=feature:poi|visibility:off",
  ];
  const mapUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${encodeURIComponent(
    address
  )}&zoom=15&size=800x400&scale=2&key=${apiKey}&${mapStyles.join("&")}`;

  return (
    <StyledFooter component="footer">
      <MapContainer>
        <StaticMap src={mapUrl} alt={`Map showing ${address}`} />
        <CustomMarker
          ishovered={ishovered.toString()}
          onMouseEnter={() => setIshovered(true)}
          onMouseLeave={() => setIshovered(false)}
        />
      </MapContainer>
      <ContentContainer maxWidth="lg">
        <NavLinks>
          <StyledLink href="/">HOME</StyledLink>
          <StyledLink href="/about">ABOUT US</StyledLink>
          <StyledLink href="/pilot-test">PILOT TEST</StyledLink>
          <StyledLink href="/contact">CONTACT</StyledLink>
          <StyledLink href="/privacy-policy">PRIVACY POLICY</StyledLink>
          <StyledLink href="/terms-of-service">TERMS OF SERVICE</StyledLink>
        </NavLinks>
        <Typography variant="body2" align="center">
          Copyright © {currentYear} All rights reserved by{" "}
          <StyledLink href="/" sx={{ fontWeight: "bold" }}>
            EON.HEALTH
          </StyledLink>
        </Typography>
      </ContentContainer>
    </StyledFooter>
  );
};

export default Footer;
