/** @format */

import React, { useState, useRef, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  Container,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import logoImage from "../assets/logo_bw.png";
import { Link, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    backgroundColor: "transparent",
    boxShadow: "none",
    padding: theme.spacing(2, 0),
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    boxShadow: "none",
  },
  logo: {
    display: "flex",
    alignItems: "center",
  },
  logoImage: {
    width: "22px",
    height: "26px",
    marginRight: theme.spacing(1),
  },
  logoText: {
    color: "#ffffff",
    fontSize: "1.5rem !important",
    fontWeight: "100 !important",
  },
  navButtons: {
    display: "flex",
    position: "relative",
  },
  navButton: {
    color: "#ffffff !important",
    marginLeft: theme.spacing(1),
    textTransform: "none",
    fontSize: "1.2rem !important",
    fontWeight: 300,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  activeNavButton: {
    // Remove the border-bottom from here
  },
  navLink: {
    textDecoration: "none",
  },
  borderBottom: {
    position: "absolute",
    bottom: -2,
    left: 0,
    width: "0",
    height: "2px",
    backgroundColor: "#ffffff",
    transition: "all 0.3s cubic-bezier(0.25, 0.1, 0.25, 1.0)",
  },
}));

const Header: React.FC = () => {
  const classes = useStyles();
  const location = useLocation();
  const [hoveredButton, setHoveredButton] = useState<string | null>(null);
  const [borderStyle, setBorderStyle] = useState({});
  const navButtonsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (hoveredButton && navButtonsRef.current) {
      const buttonElement = navButtonsRef.current.querySelector(
        `[data-button="${hoveredButton}"]`
      ) as HTMLElement;
      if (buttonElement) {
        const buttonRect = buttonElement.getBoundingClientRect();
        const containerRect = navButtonsRef.current.getBoundingClientRect();
        const left = buttonRect.left - containerRect.left;
        setBorderStyle({
          left: `${left}px`,
          width: `${buttonRect.width}px`,
        });
      }
    } else {
      setBorderStyle({ width: 0 });
    }
  }, [hoveredButton]);

  const handleMouseEnter = (buttonName: string) => {
    setHoveredButton(buttonName);
  };

  const handleMouseLeave = () => {
    setHoveredButton(null);
  };

  return (
    <AppBar
      position="absolute"
      className={classes.appBar}
      sx={{ boxShadow: "none" }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters className={classes.container}>
          <Link to="/" className={classes.navLink}>
            <Box className={classes.logo}>
              <img
                src={logoImage}
                alt="EON.HEALTH Logo"
                className={classes.logoImage}
              />
              <Typography className={classes.logoText}>EON.HEALTH</Typography>
            </Box>
          </Link>
          <Box
            className={classes.navButtons}
            ref={navButtonsRef}
            onMouseLeave={handleMouseLeave}
          >
            <Link to="/" className={classes.navLink}>
              <Button
                className={`${classes.navButton} ${
                  location.pathname === "/" ? classes.activeNavButton : ""
                }`}
                sx={{
                  marginRight: 4,
                  textTransform: "none",
                  fontFamily: "Raleway, sans-serif",
                  fontWeight: 300,
                }}
                onMouseEnter={() => handleMouseEnter("home")}
                data-button="home"
              >
                Home
              </Button>
            </Link>
            <Link to="/about" className={classes.navLink}>
              <Button
                className={`${classes.navButton} ${
                  location.pathname === "/about" ? classes.activeNavButton : ""
                }`}
                sx={{
                  marginRight: 4,
                  textTransform: "none",
                  fontFamily: "Raleway, sans-serif",
                  fontWeight: 300,
                }}
                onMouseEnter={() => handleMouseEnter("about")}
                data-button="about"
              >
                About Us
              </Button>
            </Link>
            <Link to="/pilot-test" className={classes.navLink}>
              <Button
                className={`${classes.navButton} ${
                  location.pathname === "/pilot-test"
                    ? classes.activeNavButton
                    : ""
                }`}
                sx={{
                  marginRight: 4,
                  textTransform: "none",
                  fontFamily: "Raleway, sans-serif",
                  fontWeight: 300,
                }}
                onMouseEnter={() => handleMouseEnter("pilotTest")}
                data-button="pilotTest"
              >
                Pilot Test
              </Button>
            </Link>
            <Link to="/contact" className={classes.navLink}>
              <Button
                className={`${classes.navButton} ${
                  location.pathname === "/contact"
                    ? classes.activeNavButton
                    : ""
                }`}
                sx={{
                  textTransform: "none",
                  fontFamily: "Raleway, sans-serif",
                  fontWeight: 300,
                }}
                onMouseEnter={() => handleMouseEnter("contact")}
                data-button="contact"
              >
                Contact
              </Button>
            </Link>
            <div className={classes.borderBottom} style={borderStyle} />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
